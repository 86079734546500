const socialArrows = document.querySelectorAll('.social-arrow')

if (socialArrows) {
  gsap.utils.toArray('.social-arrow').forEach((social) => {
    const circleBottom = social.querySelector('.social-arrow-link--circle')

    social.addEventListener('mouseenter', (e) => {
      let elPositionX = social.getBoundingClientRect().x
      let elPositionY = social.getBoundingClientRect().y

      let positionX = e.clientX - elPositionX
      let positionY = e.clientY - elPositionY

      gsap.set(circleBottom, {
        left: `${positionX}px`,
        top: `${positionY}px`,
        xPercent: -50,
        yPercent: -50,
      })

      gsap.fromTo(
        circleBottom,
        {
          width: '0%',
          ease: 'back.out(1.1)',
        },
        { width: '150%' },
      )
    })

    social.addEventListener('mousemove', (e) => {
      let elPositionX = social.getBoundingClientRect().x
      let elPositionY = social.getBoundingClientRect().y

      let positionX = e.clientX - elPositionX
      let positionY = e.clientY - elPositionY

      gsap.set(circleBottom, {
        duration: 0.6,
        ease: 'back.out(4)',
        left: `${positionX}px`,
        top: `${positionY}px`,
      })
    })

    social.addEventListener('mouseleave', (e) => {
      gsap.fromTo(
        circleBottom,
        {
          width: '150%',
        },
        { width: '0%' },
      )
    })
  })
}
