function scrollFixed() {
  const attachmentFixed = document.querySelector('.attachment-fixed')
  const mainContainer = document.querySelector('.main-container')

  if (attachmentFixed) {
    function fixedHeight() {
      if (attachmentFixed.classList.contains('attachment-fixed--double')) {
        let attachmentFixedHeight = (mainContainer.style.marginTop = `${
          attachmentFixed.offsetHeight * 2
        }px`)
        return attachmentFixedHeight
      } else {
        let attachmentFixedHeight =
          (mainContainer.style.marginTop = `${attachmentFixed.offsetHeight}px`)
        return attachmentFixedHeight
      }
    }

    const attachmentFixedHeight = fixedHeight()

    gsap.timeline({
      scrollTrigger: {
        trigger: attachmentFixed,
        markers: false,
        start: 'top top',
        end: `+=${attachmentFixedHeight}`,
        onLeave() {
          attachmentFixed.classList.add('scroll-end')
        },
        onEnterBack() {
          attachmentFixed.classList.remove('scroll-end')
        },
      },
    })
  }
}

function scrollBlurInit() {
  const blurContainer = document.querySelector('.section--scroll')

  if (blurContainer) {
    const blurContainerHeight = blurContainer.offsetHeight * 2

    gsap
      .timeline({
        scrollTrigger: {
          trigger: blurContainer,
          pin: false,
          markers: false,
          start: 'top top',
          end: `+=${blurContainerHeight}`,
          scrub: 1,
        },
      })
      .to('.blur-effect-wrapper', { yPercent: -100 })
      // .to('.blur-effect-wrapper', { opacity: 0 })
      .to('.blur-container', { yPercent: -100 }, '<')
      .to({}, { duration: 1 }) // like a pause

    let bgParallaxBlur = document.getElementsByClassName('bg-blur-parallax')
    new simpleParallax(bgParallaxBlur, {
      delay: 0.6,
      transition: 'cubic-bezier(0,0,0,1)',
      customContainer: blurContainer,
      customWrapper: 'div.blur-wrapper',
    })
  }
}
