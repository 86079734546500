function emailSendInit() {
  const offcanvasOverlay = document.querySelectorAll('.offcanvas-overlay')
  console.log(offcanvasOverlay)

  offcanvasOverlay.forEach((overlay) => {
    overlay.classList.add('active')
    const overlayPurple = document.querySelector('.offcanvas-body.purple')

    let tl = gsap.timeline({})

    tl.to(overlay, { opacity: 1 })
    tl.fromTo(
      overlayPurple,
      { clipPath: `circle(0% at 50% 50%)` },
      { clipPath: `circle(120% at 50% 50%)`, duration: 0.75 },
      '>+0.5',
    )
  })
}
