var gridCarousel = Swiper
var gridInit = false

function gridImageCarousel() {
  let mobile = window.matchMedia('(min-width: 0px) and (max-width: 767px)')
  let tablet = window.matchMedia('(min-width: 768px)')
  // let tablet = window.matchMedia('(min-width: 768px) and (max-width: 1024px)');
  // let desktop = window.matchMedia('(min-width: 1025px)');

  let slider = document.querySelectorAll('.slider-grid-carousel')

  // Enable (for mobile)
  if (slider[0]) {
    if (mobile.matches) {
      if (!gridInit) {
        init = true
        gridCarousel = new Swiper('.slider-grid-carousel .slider', {
          spaceBetween: 10,
          loop: true,
          noSwiping: false,
          allowTouchMove: false,
          speed: 3000,
          slidesPerView: 1.2,

          autoplay: {
            delay: 0,
          },
        })
      }
    }

    // Disable (for tablet)
    else {
      gridCarousel.destroy()
      gridInit = false
    }
  }

  // Disable (for desktop)
  /* else if (desktop.matches) {
        swiper.destroy();
        init = false;
    } */
}
