const masonryGrid = document.querySelector('.masonry-grid')

if (masonryGrid) {
  let magicGrid = new MagicGrid({
    container: masonryGrid, // Required. Can be a class, id, or an HTMLElement.
    static: true, // Required for static content.
    animate: true, // Optional.
    maxColumns: 2,
    gutter: 100,
  })

  magicGrid.listen()
  magicGrid.positionItems()
}

const masonryGridVittoria = document.querySelector('.vittoria .masonry-case')

if (masonryGridVittoria) {
  let magicGridCase = new MagicGrid({
    container: masonryGridVittoria, // Required. Can be a class, id, or an HTMLElement.
    static: true, // Required for static content.
    animate: true, // Optional.
    maxColumns: 4,
    gutter: 40,
  })

  magicGridCase.listen()
}
