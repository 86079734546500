function cardAnimation() {
  const cards = document.querySelectorAll('.card')

  if (cards[0]) {
    cards.forEach((card) => {
      let cardSection = card.closest('*[data-mask-parent]')
      let cardSeparator = card.querySelector('.card-separator')

      gsap.to(cardSeparator, {
        width: '100%',
        stagger: 0.15,
        marker: true,
        scrollTrigger: {
          trigger: cardSection,
          start: 'top center',
        },
      })
    })
  }
}
