function loadingPageInit() {
  /* const link = document.querySelectorAll('a');
    link.forEach(a => {
        let url = this.getAttribute("href")

        a.addEventListener('click', () => {
            setTimeout(function () {
                window.location.href = url;
            }, 5000);
        })
    }); */

  const loading = document.querySelector('.loading')

  if (loading) {
    let loadingTl = gsap.timeline({ ease: 'power1.inOut' })

    const loadingProgress = document.querySelector('.loading__progress')
    const loadingPercentage = document.querySelector('.loading__line--progress')
    const loadingNumber = document.querySelector('.loading__line--number')

    loadingTl.fromTo(
      '.loading__content .loading__logo',
      { opacity: 0, yPercent: -100 },
      { opacity: 1, yPercent: 0 },
    )
    loadingTl.fromTo(loadingProgress, { opacity: 0 }, { opacity: 1 }, '<')

    loadingTl.to(loadingPercentage, {
      duration: 1,
      onUpdate: function () {
        let progress = this.progress() * 100
        loadingPercentage.style.width = `${Math.round(progress)}%`
        loadingNumber.innerHTML = `${Math.round(progress)}`
      },
    })

    loadingTl.to(
      '.loading--transition',
      {
        xPercent: 100,
        stagger: 0.05,
        duration: 1,
      },
      '+=1',
    )

    loadingTl.set('.loading', {
      className: 'loading loading--end',
      onComplete() {
        body.classList.remove('overflow-hidden')
      },
    })
  }
}
