ScrollReveal().reveal('.team-wrapper .card', {
  interval: 200,
  duration: 800,
  reset: false,
  mobile: false,
})

ScrollReveal().reveal('.case-wrapper .card', {
  interval: 200,
  duration: 800,
  reset: false,
  mobile: false,
})
