function parallaxImage() {
  let bgParallax = document.querySelectorAll('.parallax-bg > *')

  new simpleParallax(bgParallax, {
    delay: 0.6,
    transition: 'cubic-bezier(0,0,0,1)',
    customWrapper: '.parallax-bg',
    // scale: 1.1
  })
}
