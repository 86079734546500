const cardBlur = document.querySelectorAll('.card-frame.card--blur')

cardBlur.forEach((el) => {
  const circleBlend = el.querySelectorAll('.card-img-bottom')

  let someProva = function prova(e) {
    console.log('tap')

    let elPositionX = el.getBoundingClientRect().x
    let elPositionY = el.getBoundingClientRect().y

    let positionX = e.clientX - elPositionX
    let positionY = e.clientY - elPositionY

    gsap.fromTo(
      circleBlend,
      1,
      {
        '-webkit-mask-image': `radial-gradient(ellipse 0% 0% at ${positionX}px ${positionY}px, black 50%, transparent 70%)`,
      },
      {
        '-webkit-mask-image': `radial-gradient(ellipse 300% 300% at ${positionX}px ${positionY}px, black 50%, transparent 70%)`,
      },
    )
  }

  el.addEventListener('mouseenter', someProva)
  // el.addEventListener("ontouchstart", someProva);

  /*  el.addEventListener("mousemove", e => {
         let elPositionX = el.getBoundingClientRect().x;
         let elPositionY = el.getBoundingClientRect().y;
 
         let positionX = e.clientX - elPositionX;
         let positionY = e.clientY - elPositionY;
 
         gsap.to(circleBlend, {
             duration: 0.5,
             // "-webkit-mask-image": `radial-gradient(ellipse 100% 100% at ${positionX}px ${positionY}px, black 50%, transparent 70%)`,
 
             // yPercent: -50,
             // xPercent: -50,
             // x: positionX,
             // y: positionY,
 
 
         })
     }); */

  el.addEventListener('mouseleave', (e) => {
    let elPositionX = el.getBoundingClientRect().x
    let elPositionY = el.getBoundingClientRect().y

    let positionX = e.clientX - elPositionX
    let positionY = e.clientY - elPositionY

    // gsap.to(circleBlend, {
    // duration: 0.5,
    // delay: 0.2,

    // "-webkit-mask-image": `radial-gradient(ellipse 100% 100% at ${positionX} ${positionY}, black 50%, transparent 70%)`,
    // })

    gsap.fromTo(
      circleBlend,
      1,
      {
        '-webkit-mask-image': `radial-gradient(ellipse 300% 300% at ${positionX}px ${positionY}px, black 50%, transparent 70%)`,
      },
      {
        '-webkit-mask-image': `radial-gradient(ellipse 0% 0% at ${positionX}px ${positionY}px, black 50%, transparent 70%)`,
      },
    )
  })
})
