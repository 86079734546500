// test scroll svg
function lineDrawing() {
  const lineDrawing = document.querySelector('.line-drawing')
  if (lineDrawing) {
    // Items
    const lineDrawingItem = lineDrawing.querySelectorAll(
      '.line-drawing__item:not(.line-drawing--end)',
    )

    const lineDrawinContent = lineDrawing.querySelectorAll(
      '.line-drawing__content',
    )
    const lineDrawingVertical = lineDrawing.querySelectorAll(
      '.line-drawing__vertical',
    )
    const lineDrawingHorizontal = lineDrawing.querySelectorAll(
      '.line-drawing__horizontal',
    )

    // Lines
    const lineVerticalSvg = lineDrawing.querySelectorAll('.line.line--vertical')
    const lineHorizontalSvg = lineDrawing.querySelectorAll(
      '.line.line--horizontal',
    )

    const lineVerticalPath = lineDrawing.querySelectorAll(
      '.line.line--vertical path',
    )
    const lineHorizontalPath = lineDrawing.querySelectorAll(
      '.line.line--horizontal path',
    )

    const lineVerticalLinePath = lineDrawing.querySelectorAll(
      '.line.line--vertical .line__path',
    )
    const lineHorizontalLinePath = lineDrawing.querySelectorAll(
      '.line.line--horizontal .line__path',
    )

    // Line - Vertical
    lineVerticalSvg.forEach((verticalSvg, i) => {
      const singleLineDrawingContent = lineDrawinContent[i]
      const verticalPath = lineVerticalPath[i]

      verticalSvg.setAttribute(
        'viewBox',
        `0 0 2 ${singleLineDrawingContent.offsetHeight}`,
      )
      verticalPath.setAttribute(
        'd',
        `M1 0v${singleLineDrawingContent.offsetHeight}`,
      )
    })

    // Line - Horizontal
    lineHorizontalSvg.forEach((horizontalSvg, i) => {
      const lineDrawingHorizontalContent = lineDrawingHorizontal[i]
      const horionztalPath = lineHorizontalPath[i]

      horizontalSvg.setAttribute(
        'viewBox',
        `-1 0 ${lineDrawingHorizontalContent.offsetWidth + 2} ${
          lineDrawingHorizontalContent.offsetHeight
        }`,
      )
      horionztalPath.setAttribute(
        'd',
        `M0 0v${
          lineDrawingHorizontalContent.offsetHeight / 2 - 16
        }c0 9 7 16 16 16h${
          lineDrawingHorizontalContent.offsetWidth - 32
        }c9 0 16 7 16 16v${lineDrawingHorizontalContent.offsetHeight / 2 - 16}`,
      )
    })

    // Scroll Animation - Vertical, Horizontal
    lineDrawingItem.forEach((el, i) => {
      const singleLineDrawingContent = lineDrawinContent[i]
      const verticalLinePath = lineVerticalLinePath[i]
      const horizontalLinePath = lineHorizontalLinePath[i]
      const lineDrawingHorizontalContent = lineDrawingHorizontal[i]

      verticalLinePath.style.strokeDasharray =
        singleLineDrawingContent.offsetHeight
      horizontalLinePath.style.strokeDasharray =
        lineDrawingHorizontalContent.offsetWidth +
        lineDrawingHorizontalContent.offsetHeight

      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: el,
          markers: false,
          start: 'top center',
          end: `+=${el.offsetHeight}`,
          scrub: 0,
        },
      })

      tl.set(verticalLinePath, {
        strokeDasharray: singleLineDrawingContent.offsetHeight,
      })
      tl.set(horizontalLinePath, {
        strokeDasharray:
          lineDrawingHorizontalContent.offsetWidth +
          lineDrawingHorizontalContent.offsetHeight,
      })

      tl.fromTo(
        verticalLinePath,
        { strokeDashoffset: singleLineDrawingContent.offsetHeight },
        { strokeDashoffset: 0, duration: 0.2 },
      )
      tl.fromTo(
        horizontalLinePath,
        {
          strokeDashoffset:
            lineDrawingHorizontalContent.offsetWidth +
            lineDrawingHorizontalContent.offsetHeight,
        },
        { strokeDashoffset: 0 },
      )
    })

    //
    // Scroll Animation - End
    //
    const lineDrawingItemEnd = lineDrawing.querySelector(
      '.line-drawing__item.line-drawing--end',
    )

    const lineDrawinEndContent = lineDrawingItemEnd.querySelector(
      '.line-drawing__content',
    )
    const lineDrawingEndVertical = lineDrawingItemEnd.querySelector(
      '.line-drawing__vertical',
    )
    const lineDrawingEndHorizontal =
      lineDrawingItemEnd.querySelector('.line-drawing__end')
    const lineDrawingEndPoint = lineDrawingEndHorizontal.querySelector('.point')

    // Lines END
    const lineEndVerticalSvg = lineDrawingItemEnd.querySelector(
      '.line.line--vertical',
    )
    const lineEndVerticalPath = lineEndVerticalSvg.querySelector('path')
    const lineEndVerticalLinePath =
      lineEndVerticalSvg.querySelector('.line__path')

    const lineEndHorizontalSvg =
      lineDrawingItemEnd.querySelector('.line.line--end')
    const lineEndHorizontalPath = lineEndHorizontalSvg.querySelector('path')
    const lineEndHorizontalLinePath =
      lineEndHorizontalSvg.querySelector('.line__path')

    // Line End - Vertical
    lineEndVerticalSvg.setAttribute(
      'viewBox',
      `0 0 2 ${lineDrawinEndContent.offsetHeight}`,
    )
    lineEndVerticalPath.setAttribute(
      'd',
      `M1 0v${lineDrawinEndContent.offsetHeight}`,
    )

    // Line End - Horizontal
    lineEndHorizontalSvg.setAttribute(
      'viewBox',
      `-1 0 ${lineDrawingEndHorizontal.offsetWidth + 1} ${
        lineDrawingEndHorizontal.offsetHeight
      }`,
    )
    lineEndHorizontalPath.setAttribute(
      'd',
      `M0 0v${lineDrawingEndHorizontal.offsetHeight / 2 - 32}c0 9 7 16 16 16h${
        lineDrawingEndHorizontal.offsetWidth - 32
      }c9 0 16 7 15 16v${lineDrawingEndHorizontal.offsetHeight / 2 - 16}`,
    )

    lineEndVerticalLinePath.style.strokeDasharray =
      lineDrawinEndContent.offsetHeight
    lineEndHorizontalLinePath.style.strokeDasharray =
      lineDrawingEndHorizontal.offsetWidth +
      lineDrawingEndHorizontal.offsetHeight

    let tlEnd = gsap.timeline({
      scrollTrigger: {
        trigger: lineDrawingItemEnd,
        markers: false,
        start: 'top center',
        end: `+=${lineDrawingItemEnd.offsetHeight}`,
        scrub: 0,
      },
    })

    tlEnd.set(lineEndVerticalLinePath, {
      strokeDasharray: lineDrawinEndContent.offsetHeight,
    })
    tlEnd.set(lineEndHorizontalLinePath, {
      strokeDasharray:
        lineDrawingEndHorizontal.offsetWidth +
        lineDrawingEndHorizontal.offsetHeight,
    })

    tlEnd.fromTo(
      lineEndVerticalLinePath,
      { strokeDashoffset: lineDrawinEndContent.offsetHeight },
      { strokeDashoffset: 0, duration: 0.2 },
    )
    tlEnd.fromTo(
      lineEndHorizontalLinePath,
      {
        strokeDashoffset:
          lineDrawingEndHorizontal.offsetWidth +
          lineDrawingEndHorizontal.offsetHeight,
      },
      { strokeDashoffset: 0 },
    )
    tlEnd.fromTo(
      lineDrawingEndPoint,
      { opacity: 0 },
      { opacity: 1, className: 'point pulse', duration: 0.1 },
    )
  }
}
