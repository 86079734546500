function teamSliderInit() {
  const swiperCase = new Swiper('.team-wrapper', {
    spaceBetween: 24,
    slidesPerView: 1.4,
    freeMode: true,

    breakpoints: {
      768: {
        slidesPerView: 2.4,
      },

      992: {
        slidesPerView: 3,
        spaceBetween: 0,
        noSwiping: true,
        allowSlidePrev: false,
        allowSlideNext: false,
      },
    },

    navigation: {
      prevEl: '.slider-navigation-prev',
      nextEl: '.slider-navigation-next',
      disabledClass: 'disabled',
    },
  })

  return swiperCase
}
