function wordMaskInit() {
  const wordMask = document.querySelectorAll('.word-mask')

  if (wordMask) {
    wordMask.forEach((word) => {
      let wordsDiv = word

      let wordMap = word.outerText.split(' ')

      wordsDiv.innerHTML = ''

      wordMap.map((el) => {
        wordsDiv.innerHTML += `<span class="word-mask__container"><span class="word-mask__child">${el}</span></span> `
      })

      let wordsChild = word.querySelectorAll('.word-mask__child')
      let wordSection = word.closest('*[data-mask-parent]')

      gsap.to(wordsChild, {
        y: 0,
        stagger: 0.15,
        scrollTrigger: {
          trigger: wordSection,
          markers: false,
          start: 'top center+=10%',
          // end: 'end end',
          // toggleActions: "play none reset none",
        },
      })
    })
  }
}
