function cardOverlay() {
  const cardOverlap = document.querySelectorAll('.card-overlap')

  if (cardOverlap[0]) {
    const circleSize = 20

    cardOverlap.forEach((card) => {
      const cardBottom = card.querySelector('.card-overlap--bottom')

      card.addEventListener('mouseenter', (e) => {
        let elPositionX = card.getBoundingClientRect().x
        let elPositionY = card.getBoundingClientRect().y

        let positionX = e.clientX - elPositionX
        let positionY = e.clientY - elPositionY

        gsap.set(cardBottom, {
          clipPath: `circle(0% at ${positionX}px ${positionY}px)`,
        })
      })

      card.addEventListener('mousemove', (e) => {
        let elPositionX = card.getBoundingClientRect().x
        let elPositionY = card.getBoundingClientRect().y

        let positionX = e.clientX - elPositionX
        let positionY = e.clientY - elPositionY

        gsap.to(cardBottom, {
          duration: 0.7,
          ease: 'back.out(4)',
          clipPath: `circle(80% at ${positionX}px ${positionY}px)`,
        })
      })

      card.addEventListener('mouseleave', (e) => {
        let elPositionX = card.getBoundingClientRect().x
        let elPositionY = card.getBoundingClientRect().y

        let positionX = e.clientX - elPositionX
        let positionY = e.clientY - elPositionY

        gsap.to(cardBottom, {
          duration: 0.5,
          delay: 0.2,
          clipPath: `circle(0% at ${positionX}px ${positionY}px)`,
        })
      })
    })
  }
}
