function hrAnimation() {
  const lines = document.querySelectorAll('hr.line-animation')

  lines.forEach((line) => {
    /* gsap.to(line, {
            scrollTrigger: {
                trigger: line,
                // toggleActions: "restart none none reset",
                markers: true,
                // start: "top-=25% center"
                start: "top center"
            }  
        }); */

    gsap.to(line, {
      scrollTrigger: line, // start the animation when ".box" enters the viewport (once)
      width: '100%',
      markers: true,
      duration: 1,
    })
  })
}
